html.mdef:root {
    --account-avatar-text-color: var(--color-font-900);
    --account-avatar-color: var(--color-font-900);
    --account-avatar-outline-color: var(--color-primary-50);
    --account-avatar-bg: var(--color-primary-50);

    --breadcrumb-current-item-color: var(--color-font-700);
    --breadcrumb-item-color: #3B4044;
    --breadcrumb-separator-color: var(--color-font-700);
    --breadcrumb-border-color: var(--color-surface-200);

    --slider-on-bg: var(--color-state-green-700);
    --slider-bg: var(--color-font-700);
    --slider-outline-color-focus: rgb(15 27 41 / 20%);

    --form-control-border-color: var(--color-surface-600);
    --form-control-box-shadow-focus: rgb(15 27 41 / 20%);
    --form-control-border-radius: 0.2rem;
    --form-control-bg: var(--color-surface-50);
}
