@use 'mixins';

// Style User Selection
::selection {
    background-color: var(--base-selection-bgcolor);

    color: var(--base-select-textcolor, #000);
}

// Since the default root element font size in most browsers is 16px,
// using html { font-size: 62.5%; } makes the root element font size 10px,
// so everywhere else 1rem translates to 10px. Easier for the brains.
html {
    box-sizing: border-box;

    font-size: 62.5%;
}

// Makes it easier to overwrite box-sizing on specific elements
*,
*::before,
*::after {
    box-sizing: inherit;
}

/* Default font-size: 16px (1.6rem); */
html.mdef body {
    position: absolute;
    inset: 0;

    background-color: var(--body-bg, var(--color-surface-50));

    color: var(--main-text-color, #000);

    font-family: var(--main-font-stack);
    font-size: var(--main-text-size, 1.6rem);

    -webkit-font-smoothing: antialiased;

    font-weight: var(--font-base, 1.6rem);
    line-height: var(--leading-base, 1.5);
}

