@use 'normalize.css';

:root {
  --color-blue-dark: #0F1B29;
  --color-grey: #303030;
  --color-orange: #FF7F00;

  --color-gradient-orange: linear-gradient(180deg, #FF9D3C 35.5%, #FF7F00 100%);
  --color-gradient-silver: linear-gradient(180deg, #FFFFFF 35.5%, #6E7B90 100%, #98A5B9 100%);

  --bg-color-dark: var(--color-black);
  --text-color-light: var(--color-white);

  --spacing-190: 19rem;
}

html,
body {;
  --body-bg: var(--bg-color-dark);
  block-size: 100%;
  color: var(--text-color-light);
}