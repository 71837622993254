$fonts-path: '../../assets/fonts';

/* Duplicate this for all the fonts and font-weights */
/* Rijksoverheid-sans Regular */
@font-face {
    font-family: 'Rijksoverheid-sans';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('#{$fonts-path}/rijksoverheid-sans-regular.woff2') format('woff2');
    font-display: swap;
}

/* Rijksoverheid-sans Bold */
@font-face {
    font-family: 'Rijksoverheid-sans';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('#{$fonts-path}/rijksoverheid-sans-bold.woff2') format('woff2');
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    src: url('#{$fonts-path}/PTSans-Regular-subset.woff2') format('woff2');
  }

  @font-face {
    font-display: swap;
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 600;
    src: url('#{$fonts-path}/PTSans-Bold-subset.woff2') format('woff2');
  }
