@use 'mixins';

.sr-only {
    position: absolute;
    block-size: 1px;
    inline-size: 1px;
    padding: 0;
    clip: rect(1px, 1px, 1px, 1px);

    border: 0;

    overflow: hidden;

    &.focusable {
        &:focus {
            block-size: auto;
            inline-size: auto;

            clip: auto;
        }
    }
}

.line-clamp-1 {
    @include mixins.line-clamp(1);
}

.max-w-50ch {
    max-inline-size: 50ch;
}

.block-link-overlay {
    &::after {
        position: absolute;
        inset: 0;
        content: '';

        z-index: 1;
    }

    &:hover {
        cursor: pointer;
    }
}
