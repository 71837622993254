@use 'sass:math';

// Make SR-only elements
@mixin sr-only {
    position: absolute;
    block-size: 1px;
    inline-size: 1px;
    padding: 0;

    clip: rect(1px, 1px, 1px, 1px);

    border: 0;

    overflow: hidden;
}

// Make SR-only elements visible again
@mixin sr-only-visible {
    position: relative;
    block-size: auto;
    inline-size: auto;
    padding: initial;

    clip: auto;

    border: none;

    overflow: visible;
}

@mixin transition(
    $property: all,
    $speed: 0.3s,
    $timing: cubic-bezier(0.5, 0, 0.5, 1),
    $delay: 0s
) {
    transition: $property $speed $timing $delay;
}

@mixin scrollbars(
    $size: 10px,
    $foreground-color: #999,
    $background-color: #333
) {
    // For Chrome & Safari
    &::-webkit-scrollbar {
        block-size: $size;
        inline-size: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    // Standard version (Firefox only for now)
    scrollbar-color: $foreground-color $background-color;
}

@mixin line-clamp($clamp: 3) {
    display: -webkit-box;

    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $clamp;
}

@mixin focus-outline-styling(
    $color: var(--color-outline-styling, var(--color-black)),
    $offset: 0.2rem,
    $width: 0.2rem
) {
    outline: $width solid $color;
    outline-offset: $offset;

    transition: outline-offset 0.2s ease;
}

$default-min-bp: 320px;
$default-max-bp: 1920px;

@function decimalround($number, $decimals: 2) {
    $n: 1;

    @if $decimals > 0 {
        @for $i from 1 through $decimals {
            $n: $n * 10;
        }
    }

    @return math.div(math.round($number * $n), $n);
}

@function px-to-rem($px) {
    $rems: math.div($px, 10px) * 1rem;

    @return $rems;
}

@function rem-to-px($rem) {
    $pxs: math.div($rem, 1rem) * 10px;

    @return $pxs;
}

@function clamp-calc($min-size, $max-size, $min-breakpoint: $default-min-bp, $max-breakpoint: $default-max-bp, $unit: vw) {
    $min-size-px: decimalround(rem-to-px($min-size), 2);
    $max-size-px: decimalround(rem-to-px($max-size), 2);
    $slope: math.div(($max-size-px - $min-size-px), $max-breakpoint - $min-breakpoint);
    $slope-to-unit: decimalround($slope * 100, 2);
    $intercept-rem: decimalround(px-to-rem($min-size-px - $slope * $min-breakpoint), 2);

    @return clamp(#{$min-size}, #{$slope-to-unit}#{$unit} + #{$intercept-rem}, #{$max-size});
}

@function hex-to-rgba($hex, $alpha) {
    $red: color.red($hex);
    $green: color.green($hex);
    $blue: color.blue($hex);
    $rgba: rgba($red, $green, $blue, $alpha);

    @return $rgba;
}