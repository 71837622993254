/* stylelint-disable */
html.mdef:root {
    /* COLORS */
    /* -------------------------------------------------- */
    /* Primary colors */
    --color-primary-50: #E6E8EB;
    --color-primary-200: #D4D7DD;
    --color-primary-700: #2A4C74;
    --color-primary-800: #142538;
    --color-primary-900: #0F1B29;

    /* Secondary colors */
    --color-secondary-800: #FF8A16;
    --color-secondary-900: #FF7F00;

    /* Tertiary colors */
    --color-tertiary-50: #F9FAFA;

    /* Surface colors */
    --color-surface-50: #F4F5F6;
    --color-surface-100: #F1F2F4;
    --color-surface-200: #E5EDF5;
    --color-surface-300: #4360A3;
    --color-surface-400: #344A7E;
    --color-surface-500: #25355A;
    --color-surface-600: #162036;
    --color-surface-700: #111322;
    --color-surface-800: #0A0E17;
    --color-surface-900: #070A11;

    /* Font colors */
    --color-font-50: #FAFAFA;
    --color-font-100: #F5F5F5;
    --color-font-200: #EFEFEF;
    --color-font-300: #E1E1E1;
    --color-font-400: #BEBEBE;
    --color-font-700: #7E889A;
    --color-font-800: #5A6C77;
    --color-font-900: #181D20;

    /* State green */
    --color-state-green-50: #A2E6BA;
    --color-state-green-100: #DFF6E7;
    --color-state-green-200: #CBF1D7;
    --color-state-green-300: #B7EBC9;
    --color-state-green-400: #7ADB9B;
    --color-state-green-500: #3ECB6E;
    --color-state-green-600: #2DA957;
    --color-state-green-700: #238143;
    --color-state-green-800: #1D6C38;
    --color-state-green-900: #18582E;

    /* State yellow */
    --color-state-yellow-50: #FFF4CC;
    --color-state-yellow-100: #FFEEB3;
    --color-state-yellow-200: #FFE999;
    --color-state-yellow-300: #FFE380;
    --color-state-yellow-400: #FFDE66;
    --color-state-yellow-500: #FFD84D;
    --color-state-yellow-600: #FFD333;
    --color-state-yellow-700: #FFCD1A;
    --color-state-yellow-800: #FFC800;
    --color-state-yellow-900: #E5B400;
    --color-state-yellow-950: #4D3C00;

    /* State red */
    --color-state-red-50: #FBEBEB;
    --color-state-red-100: #F5D5D5;
    --color-state-red-200: #F1C1C1;
    --color-state-red-300: #ECABAB;
    --color-state-red-400: #E69696;
    --color-state-red-500: #E28282;
    --color-state-red-600: #DE6D6D;
    --color-state-red-700: #E30000;
    --color-state-red-800: #BB2B2B;
    --color-state-red-900: #7D1C1C;

    /* Other colors */
    --color-white: #FFF;
    --color-black: #000;
    --color-text-error: #FF0000;
    --color-gradient-silver: linear-gradient(180deg, #FFFFFF 35.5%, #6E7B90 100%, #98A5B9 100%);
    --color-gradient-orange: linear-gradient(180deg, #FF9D3C 35.5%, #FF7F00 100%);

    /* FONTS */
    --main-font-stack: 'Rijksoverheid-sans', -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, oxygen-sans, ubuntu, cantarell, 'Helvetica Neue', sans-serif;
    --heading-font-stack: 'PT Sans', -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, oxygen-sans, ubuntu, cantarell, 'Helvetica Neue', sans-serif;

    /* Font sizes (Also available as SCSS vars because we need them in clamp functions */
    /* -------------------------------------------------- */
    --text-xs: 1.2rem;
    --text-sm: 1.4rem;
    --text-base: 1.6rem;
    --text-lg: 1.8rem;
    --text-xl: 2rem;
    --text-2xl: 2.4rem;
    --text-3xl: 2.8rem;
    --text-4xl: 3.6rem;
    --text-5xl: 5.2rem;
    --text-6xl: 5.8rem;
    --text-7xl: 6.4rem;
    --text-8xl: 8.8rem;
    --text-9xl: 9.6rem;

    /* Font weights */
    /* -------------------------------------------------- */
    --font-light: 300;
    --font-normal: 400;
    --font-medium: 500;
    --font-semibold: 600;
    --font-bold: 700;
    --font-extrabold: 800;
    --font-black: 900;
    --font-base: var(--font-normal);

    --leading-h1: 1.15;
    --leading-base: 1.6;

    /* SPACINGS
    /* -------------------------------------------------- */
    --spacing-4: 0.4rem;
    --spacing-6: 0.6rem;
    --spacing-8: 0.8rem;
    --spacing-10: 1rem;
    --spacing-12: 1.2rem;
    --spacing-16: 1.6rem;
    --spacing-20: 2rem;
    --spacing-24: 2.4rem;
    --spacing-32: 3.2rem;
    --spacing-40: 4rem;
    --spacing-48: 4.8rem;
    --spacing-56: 5.6rem;
    --spacing-64: 6.4rem;
    --spacing-72: 7.2rem;
    --spacing-80: 8rem;
    --spacing-96: 9.6rem;

    /* BORDER RADIUS
    /* -------------------------------------------------- */
    --rounded: var(--rounded-4);
    --rounded-4: 0.4rem;
    --rounded-6: 0.6rem;
    --rounded-8: 0.8rem;
    --rounded-16: 1.6rem;
    --rounded-24: 2.4rem;
    --rounded-48: 4.8rem;

    /* GRID */
    /* -------------------------------------------------- */
    --max-container-width: 1180px;
    --outer-margin: 2rem;

    /* SHORTCODES */
    /* -------------------------------------------------- */
    --color-border: initial;
    --color-border-light: initial;
    --color-outline: initial;
    --main-text-color: var(--color-font-900);
    --main-text-size: var(--text-base);
    --color-error: initial;
    --base-selection-bgcolor: var(--color-black);
    --base-select-textcolor: var(--color-white);
}
/* stylelint-enable */
